body {
  margin: 0;
  font-family: "Source Sans Pro", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Source Sans Pro", Arial, sans-serif;
}


::-webkit-scrollbar {
  height: 6px;
  width: 6px;
}

::-webkit-scrollbar-track {
  background-color: #f9f9f9;
  width: 14px;
}

::-webkit-scrollbar-thumb {
  background: rgba(32, 32, 32, 0.54);
  border-radius: 4px;
}